import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import { EuiSpacer } from '@elastic/eui';
import PrimaryBox from 'components/Software/Home_Automation/PrimaryBox';
import TimeLine from 'components/Products/CommentList/MqttIFTTTList';
import NavButtons from 'components/Software/Home_Automation/NavButtons';
import ForumBox from 'components/Software/Home_Automation/IP_Symcon/ForumBox';
import BreadCrumbs from 'components/Layout/BreadCrumbs';
import SEOHelmet from 'components/Layout/SEOHelmet';
export const _frontmatter = {
  "title": "IP Symcon Smarthome",
  "path": "/Software/Home_Automation/IP_Symcon/",
  "dateChanged": "2019-04-25",
  "author": "Mike Polinowski",
  "excerpt": "The IP-Symcon software is the complete solution in the field of home automation. Combine products of all renowned manufacturers compatible with all INSTAR HD and Full HD IP Cameras. A multitude of assistants and tools allow a comfortable configuration.",
  "social": "/images/Search/P_SearchThumb_Symcon.webp",
  "toc": "/images/Search/TOC_Icons/Wiki_Tiles_P-IPSymcon_white.webp",
  "chapter": "Software",
  "category": "smarthome",
  "type": "IP Symcon"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">





    <SEOHelmet title='IP Symcon Smarthome' dateChanged='2019-04-25' author='Mike Polinowski' tag='INSTAR IP Camera' description='The IP-Symcon software is the complete solution in the field of home automation. Combine products of all renowned manufacturers compatible with all INSTAR HD and Full HD IP Cameras. A multitude of assistants and tools allow a comfortable configuration.' image='/images/Search/P_SearchThumb_Symcon.png' twitter='/images/Search/P_SearchThumb_Symcon.webp' location={props.location.pathname} mdxType="SEOHelmet" />
    <BreadCrumbs locationBC={props.location} locationEN={props.location.pathname} locationDE='/de/Home_Automation/IP_Symcon/' locationFR='/fr/Home_Automation/IP_Symcon/' crumbLabel="IP Symcon" mdxType="BreadCrumbs" />
    <NavButtons mdxType="NavButtons" />
    <h1 {...{
      "id": "software",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h1" {...{
        "href": "#software",
        "aria-label": "software permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Software`}</h1>
    <h2 {...{
      "id": "home-automation",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#home-automation",
        "aria-label": "home automation permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Home Automation`}</h2>
    <h3 {...{
      "id": "ip-symcon-smarthome",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#ip-symcon-smarthome",
        "aria-label": "ip symcon smarthome permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`IP Symcon Smarthome`}</h3>
    {/* TOC */}
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "#home-automation"
        }}>{`Home Automation`}</a>
        <ul parentName="li">
          <li parentName="ul"><a parentName="li" {...{
              "href": "#ip-symcon-smarthome"
            }}>{`IP Symcon Smarthome`}</a></li>
        </ul>
      </li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "#introduction"
        }}>{`Introduction`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "#how-does-it-work"
        }}>{`How does it work`}</a>
        <ul parentName="li">
          <li parentName="ul"><a parentName="li" {...{
              "href": "#use-external-sensors"
            }}>{`Use external sensors`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "#activate-nightvision-only-when-motion-is-detected"
            }}>{`Activate Nightvision only when Motion is detected`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "#schedule-different-positions"
            }}>{`Schedule different Positions`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "#use-different-detection-areas-for-each-position"
            }}>{`Use different Detection Areas for each Position`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "#use-different-sensitivities-for-day--night"
            }}>{`Use different Sensitivities for Day & Night`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "#turn-off-all-cameras-when-you-arrive-home"
            }}>{`Turn off all Cameras when you arrive home`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "#use-external-sensors-to-turn-your-camera-when-motion-is-detected"
            }}>{`Use external Sensors to turn your Camera when Motion is detected`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "#use-external-sensors-to-trigger-alarm-recordings"
            }}>{`Use external Sensors to trigger Alarm Recordings`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "#nach-ger%C3%A4uschquellen-suchen"
            }}>{`Nach Geräuschquellen suchen`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "#manually-disable-all-alarm-functions-for-all-your-cameras"
            }}>{`Manually disable all alarm functions for all your cameras`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "#switch-between-pir-and-software-motion-detection"
            }}>{`Switch between PIR and Software Motion Detection`}</a></li>
        </ul>
      </li>
    </ul>
    {/* /TOC */}
    <EuiSpacer mdxType="EuiSpacer" />
    <TimeLine mdxType="TimeLine" />
    <EuiSpacer mdxType="EuiSpacer" />
    <h2 {...{
      "id": "introduction",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#introduction",
        "aria-label": "introduction permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Introduction`}</h2>
    <p>{`The `}<a parentName="p" {...{
        "href": "https://www.symcon.de/"
      }}>{`IP-Symcon software`}</a>{` is the complete solution in the field of home automation. Combine products of all renowned manufacturers, realize your wishes, and allow your home to think for itself. A multitude of assistants and tools allow a comfortable configuration with a focus on clarity and security. Needless to say, privacy is a must with the optional Cloud connection.`}</p>
    <p>{`The IP Symcon Software can be used on the proprietary Hardware called `}<a parentName="p" {...{
        "href": "https://www.symcon.de/shop/#symbox"
      }}>{`SymBox Neo`}</a>{`. The SymBox Neo is the complete solution with the integrated SymOS specially developed for IP-Symcon.`}</p>
    <p>{`Alternatively, start by downloading and installing the IP-Symcon Software version 5.x on `}<a parentName="p" {...{
        "href": "https://www.symcon.de/service/dokumentation/installation/windows/"
      }}>{`Windows`}</a>{`, `}<a parentName="p" {...{
        "href": "https://www.symcon.de/service/dokumentation/installation/macos/"
      }}>{`macOS`}</a>{`, `}<a parentName="p" {...{
        "href": "https://www.symcon.de/service/dokumentation/installation/linux/"
      }}>{`Linux (Debian)`}</a>{`, `}<a parentName="p" {...{
        "href": "https://www.symcon.de/service/dokumentation/installation/raspberry-pi/"
      }}>{`Raspberry Pi`}</a>{`, `}<a parentName="p" {...{
        "href": "https://www.symcon.de/service/dokumentation/installation/qnap/"
      }}>{`QNAP`}</a>{` or `}<a parentName="p" {...{
        "href": "https://www.symcon.de/service/dokumentation/installation/synology/"
      }}>{`Synology`}</a>{`.`}</p>
    <p>{`Example: `}<a parentName="p" {...{
        "href": "/en/Software/Other_Platforms/IP_Symcon/Installation_on_a_RaspberryPi/"
      }}>{`Installation on a RaspberryPi`}</a></p>
    <p>{`We will be using the `}<a parentName="p" {...{
        "href": "https://github.com/Wolbolar/IPSymconInstar/blob/master/docs/README.md"
      }}>{`INSTAR Module`}</a>{` to connect the IP Symcon installation with an INSTAR IP camera.`}</p>
    <p>{`If you are looking for a deep dive into setting up your IP Symcon automation with an INSTAR IP camera, switch to our `}<a parentName="p" {...{
        "href": "/en/Software/Other_Platforms/IP_Symcon/"
      }}>{`FULL GUIDE`}</a>{` instead.`}</p>
    <h2 {...{
      "id": "how-does-it-work",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#how-does-it-work",
        "aria-label": "how does it work permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`How does it work`}</h2>
    <EuiSpacer mdxType="EuiSpacer" />
    <PrimaryBox mdxType="PrimaryBox" />
    <EuiSpacer mdxType="EuiSpacer" />
    <h3 {...{
      "id": "1-use-external-sensors",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#1-use-external-sensors",
        "aria-label": "1 use external sensors permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`1. Use external sensors`}</h3>
    <p>{`Man kann durch einen Bewegungsmelder der IP-Symcon bekannt ist, sei die nun KNX, LCN, Homematic oder alle Systeme die von IP-Symcon unterstützt werden, die Kamera auf eine bestimmte Position anfahren. Angenommen in der Einfahrt löst ein Bewegungsmelder aus kann dann die Kamera automatisch auf den passenden Bildbereich schwenken.`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "800px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/a894d3b81788a9634913f32f2e85c363/5a190/IP-Symcon-EN_01a.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "56.086956521739125%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAALCAIAAADwazoUAAAACXBIWXMAAAsTAAALEwEAmpwYAAAB0ElEQVQoz12O3Y6TQBhAeT0fxTfRvfHWe2NWs8ZEE03MGt3Y7S7W7BZoaahiSwtTygxDYQaG+QFGaatpdnIuJl/OyfcZWnd7NK+5EFL3r2OszgnBaZplWQg2nNdd1zslpRDCPM+VUlpro3f7uUYoRxmhWueqw51eUUEKcj0YvPr0Bbd6jYsoL3nb7nY5P+7QRkPCAzwLOF7IbCmyQGZBhX45dzcf3l3gcFohP4vcPJ7LIqzxQhbrQ2KIxPrHWGFf4EjlMU+DFjlxZP0MnBbZEjkNslRsis2tgPb/xBBwsmfKt+OGgqamrahUtWvxLNq4K+A20OHQ5thv3OdyfCZST0DnUBn73xEW/qD+sFyYxL9ut/ez1RRs3AZauvDuB++9t4/15Iz3sX3wDZHYR6AjwEgsv/WAkUjsle8MB1ckvNPZ5Orjy8tnj7T7pIaz/c19YjBw0xMN2fY7s94w85yZ55V1wbYmAr7nzYtopFdfx5cv5p+f6uXrCpjHBNwYsiUnFLylvKWNpkIRjBGlpBak0+U2CZigStenviGb4hRQS8TKMPSi+Pdmu0zQOk6WIPZrnqmmkGp3Kj+MU15SScsqoRVkPGUclyyt6pTL3QPzb/wH+jdaToBkQEMAAAAASUVORK5CYII=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/a894d3b81788a9634913f32f2e85c363/e4706/IP-Symcon-EN_01a.avif 230w", "/en/static/a894d3b81788a9634913f32f2e85c363/d1af7/IP-Symcon-EN_01a.avif 460w", "/en/static/a894d3b81788a9634913f32f2e85c363/c9875/IP-Symcon-EN_01a.avif 800w"],
              "sizes": "(max-width: 800px) 100vw, 800px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/a894d3b81788a9634913f32f2e85c363/a0b58/IP-Symcon-EN_01a.webp 230w", "/en/static/a894d3b81788a9634913f32f2e85c363/bc10c/IP-Symcon-EN_01a.webp 460w", "/en/static/a894d3b81788a9634913f32f2e85c363/d00b9/IP-Symcon-EN_01a.webp 800w"],
              "sizes": "(max-width: 800px) 100vw, 800px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/a894d3b81788a9634913f32f2e85c363/81c8e/IP-Symcon-EN_01a.png 230w", "/en/static/a894d3b81788a9634913f32f2e85c363/08a84/IP-Symcon-EN_01a.png 460w", "/en/static/a894d3b81788a9634913f32f2e85c363/5a190/IP-Symcon-EN_01a.png 800w"],
              "sizes": "(max-width: 800px) 100vw, 800px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/a894d3b81788a9634913f32f2e85c363/5a190/IP-Symcon-EN_01a.png",
              "alt": "IP Symcon",
              "title": "IP Symcon",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`Hierzu wird ein Ereignis in IP-Symcon angelegt. Als Auslöser wird die Variable des Bewegungsmelders ausgewählt als Instanz die Kamera selber. Mehr Informationen finden Sie auch in der `}<a parentName="p" {...{
        "href": "https://github.com/Wolbolar/IPSymconInstar/blob/master/docs/de/README.md"
      }}>{`README`}</a>{` des INSTAR Addons.`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "920px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/6c354584db83f76880c5e1d5e73b53f7/f09ab/IP-Symcon-EN_01b.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "76.95652173913044%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAPCAIAAABr+ngCAAAACXBIWXMAAAsTAAALEwEAmpwYAAAB9UlEQVQoz3WTyW4TURBF/V3s2SNWYcUCCQRiacBu53+S34mYYrclnMmxu/3mqd5YqLtxlDjmrN+tW3Wr3shBiJYk/UeY4ENAxBgTIn7/+evFy1emud81zerm7vbuftO0m4YQypRSAICII/DBGba7X9ysN9tto5TKOSPifL54/eYtodR7AIDQ10XEsifnPAIAa4FxJaVgbF+1lMv54v3Hz4x2aK2V1pRLfMooeM+MvtisudAxpZxzjBERf1/O3334xDmXUhpjeqdyKAYA5xyhhFI62HrvEbGu62o6ZYwZY/KeY2ILxoB14H2IMXrvSyl1XY/HY865Mcb2hL7oA6WUkfegpd2ueUOosS70IOJyuZxMJpzznHNKKcToQzwUd84euJacMaWV6xnankwmjDEhpbVWKS2UedZ28I4KvVybEPI+sKHtaT8zIWRIoZRyRGyZaBdXW0JcP5uzFlNazudVVQkhpJTDSQzrPQysMw/eWev7gX2MBbG+vp5W1bCnIYUjzg4gOAvthsh/j5IQeH6+OjurZjMhxEPP/3F2jhLStjtKCJHSrlZ4crIdj09PTymlnHNr7WP9k/M0xonO1ncA+Bgz4u2urWYzSgll3fE8N+/EMfi2lRc/1g0hznUfwAOUFK9XV1++fttsqRBdYI89u1tKKaf8F09oSGE4GtkZAAAAAElFTkSuQmCC')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/6c354584db83f76880c5e1d5e73b53f7/e4706/IP-Symcon-EN_01b.avif 230w", "/en/static/6c354584db83f76880c5e1d5e73b53f7/d1af7/IP-Symcon-EN_01b.avif 460w", "/en/static/6c354584db83f76880c5e1d5e73b53f7/7f308/IP-Symcon-EN_01b.avif 920w", "/en/static/6c354584db83f76880c5e1d5e73b53f7/09946/IP-Symcon-EN_01b.avif 1250w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/6c354584db83f76880c5e1d5e73b53f7/a0b58/IP-Symcon-EN_01b.webp 230w", "/en/static/6c354584db83f76880c5e1d5e73b53f7/bc10c/IP-Symcon-EN_01b.webp 460w", "/en/static/6c354584db83f76880c5e1d5e73b53f7/966d8/IP-Symcon-EN_01b.webp 920w", "/en/static/6c354584db83f76880c5e1d5e73b53f7/e19c7/IP-Symcon-EN_01b.webp 1250w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/6c354584db83f76880c5e1d5e73b53f7/81c8e/IP-Symcon-EN_01b.png 230w", "/en/static/6c354584db83f76880c5e1d5e73b53f7/08a84/IP-Symcon-EN_01b.png 460w", "/en/static/6c354584db83f76880c5e1d5e73b53f7/c0255/IP-Symcon-EN_01b.png 920w", "/en/static/6c354584db83f76880c5e1d5e73b53f7/f09ab/IP-Symcon-EN_01b.png 1250w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/6c354584db83f76880c5e1d5e73b53f7/c0255/IP-Symcon-EN_01b.png",
              "alt": "IP Symcon",
              "title": "IP Symcon",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <EuiSpacer mdxType="EuiSpacer" />
    <h3 {...{
      "id": "2-activate-nightvision-only-when-motion-is-detected",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#2-activate-nightvision-only-when-motion-is-detected",
        "aria-label": "2 activate nightvision only when motion is detected permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`2. Activate Nightvision only when Motion is detected`}</h3>
    <p>{`Activate your cameras night vision according to a schedule or triggered by an external sensor integrated in home automation system:`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "800px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/bc3af60d0fb1f3e50db80d7dfc1f99ff/5a190/IP-Symcon-EN_02a.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "56.086956521739125%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAALCAIAAADwazoUAAAACXBIWXMAAAsTAAALEwEAmpwYAAACZklEQVQoz2Pg2f/D/eJfj/O/nc7+Uj/2Q/nId8WjPyX3f2bY9E5l/ye1Yz9Mz/x1O//b/swvrwu/7c/+cr/wx/TEN76d7xm2fGTgmbxXbvJO5VkH1OYd0Z5/xHzxUY05B8J23bHe+9xl133d+Yf5e7epzdynPPugztyD2nMPKc7Ya7PhqsXeF6prbzCkxMXMmDbV091NT1vLxEBfR1PT1dn56KFD+3fvW7t8RWNVxdQJfb6eHk52tk72ds72dvbWlnu2bT1+4NCKRYsY1qxb//fPH/+wCC0nb1M3b2VLx/buvi/ffi1ZvrquoeHMuXNfv34LCIsw8g2z8g3WdvEpqqj+8Onr5h17S8rKGaKioysrKkxMTY0trUytbbWMTLJycg8eOj5h0hQPV6ea6uqCwkIHBwdvb29fH18XD8/4lLRdO/YuX7rSUE+HwcDAQE9Pz8zMzNTExNTU1NjQ0M7OrrWzO8jHNzHAPzg4WFNT09zcHGS6qamFiYmvqcnE6tqE6FgXY0MGczAwRQZmZkaamn42Fn39nRGRkYaGhmZmZtampgGWlolmZiWGhl7q6m4WplUVRQympmgaTY2NjV1tbSqqS7v7u8JCQ/X19a2srAI9PUPt7BJtbBItLJzNTcsrS6rqKzE1mxkZGrp6eZWlhvS2VodHRWloaPj7+/dPmGBkYOBpZeVjYWHj6FSeFVVdkISuGeRtY2MnJ4c4D+PK3ITQ8AgjI6Pw8PCMjAxnZ2c7W1tzExMbW5sEH6ukIEcsmsHARFdPJzAwICQ42MzMzNXV1dbW1sXFxdbWFhSqJibGRkbGRoYAwFz2W7pz3coAAAAASUVORK5CYII=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/bc3af60d0fb1f3e50db80d7dfc1f99ff/e4706/IP-Symcon-EN_02a.avif 230w", "/en/static/bc3af60d0fb1f3e50db80d7dfc1f99ff/d1af7/IP-Symcon-EN_02a.avif 460w", "/en/static/bc3af60d0fb1f3e50db80d7dfc1f99ff/c9875/IP-Symcon-EN_02a.avif 800w"],
              "sizes": "(max-width: 800px) 100vw, 800px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/bc3af60d0fb1f3e50db80d7dfc1f99ff/a0b58/IP-Symcon-EN_02a.webp 230w", "/en/static/bc3af60d0fb1f3e50db80d7dfc1f99ff/bc10c/IP-Symcon-EN_02a.webp 460w", "/en/static/bc3af60d0fb1f3e50db80d7dfc1f99ff/d00b9/IP-Symcon-EN_02a.webp 800w"],
              "sizes": "(max-width: 800px) 100vw, 800px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/bc3af60d0fb1f3e50db80d7dfc1f99ff/81c8e/IP-Symcon-EN_02a.png 230w", "/en/static/bc3af60d0fb1f3e50db80d7dfc1f99ff/08a84/IP-Symcon-EN_02a.png 460w", "/en/static/bc3af60d0fb1f3e50db80d7dfc1f99ff/5a190/IP-Symcon-EN_02a.png 800w"],
              "sizes": "(max-width: 800px) 100vw, 800px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/bc3af60d0fb1f3e50db80d7dfc1f99ff/5a190/IP-Symcon-EN_02a.png",
              "alt": "IP Symcon",
              "title": "IP Symcon",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`The activation of the night vision in case of an alarm is of course only possible if the camera has an internal PIR sensor for motion detection, or if you use the alarm signal of an external sensor in home automation to "focus" the camera. The advantage - the camera is inconspicuous at night and the IR light attracts no insects / spiders. And the power consumption is of course also pressed.`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "798px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/2546a84c357e54fd4c13080af7ec57a9/898f6/IP-Symcon-EN_02b.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "42.173913043478265%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAICAYAAAD5nd/tAAAACXBIWXMAAAsTAAALEwEAmpwYAAAA5ElEQVQoz52SSY6DQAxFOSF7hnMAK+A0rDlUFkFiCPNU8FvfnYqAbkVRSnLZsuxX31YZtm0jDEOM44i+77FtG5RS4nnoV6Wgz77vL69j1vi+D7IMy7IQRRHWdUWe56jrGmVZoqoqKX60PW73/A/wCvc87xfIi0CqIrBpGoGfmg9q/lN4ArquiziOBUJ1tK7rZAzdpOOPFJqmKfOzKcsytG2LZVlE8VfAJEmQpqnAiqI4FWsjnI9c80dgEARwHAcGE9M0nUa97ojrmOf5rcIXcBgG+S70BKrnFzkCmSNQj380ndPAH1fvSKBFBIqkAAAAAElFTkSuQmCC')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/2546a84c357e54fd4c13080af7ec57a9/e4706/IP-Symcon-EN_02b.avif 230w", "/en/static/2546a84c357e54fd4c13080af7ec57a9/d1af7/IP-Symcon-EN_02b.avif 460w", "/en/static/2546a84c357e54fd4c13080af7ec57a9/7d2e5/IP-Symcon-EN_02b.avif 798w"],
              "sizes": "(max-width: 798px) 100vw, 798px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/2546a84c357e54fd4c13080af7ec57a9/a0b58/IP-Symcon-EN_02b.webp 230w", "/en/static/2546a84c357e54fd4c13080af7ec57a9/bc10c/IP-Symcon-EN_02b.webp 460w", "/en/static/2546a84c357e54fd4c13080af7ec57a9/ce206/IP-Symcon-EN_02b.webp 798w"],
              "sizes": "(max-width: 798px) 100vw, 798px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/2546a84c357e54fd4c13080af7ec57a9/81c8e/IP-Symcon-EN_02b.png 230w", "/en/static/2546a84c357e54fd4c13080af7ec57a9/08a84/IP-Symcon-EN_02b.png 460w", "/en/static/2546a84c357e54fd4c13080af7ec57a9/898f6/IP-Symcon-EN_02b.png 798w"],
              "sizes": "(max-width: 798px) 100vw, 798px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/2546a84c357e54fd4c13080af7ec57a9/898f6/IP-Symcon-EN_02b.png",
              "alt": "IP Symcon",
              "title": "IP Symcon",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <EuiSpacer mdxType="EuiSpacer" />
    <h3 {...{
      "id": "3-schedule-different-positions",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#3-schedule-different-positions",
        "aria-label": "3 schedule different positions permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`3. Schedule different Positions`}</h3>
    <p>{`Scheduled day and night position - guard the front door when I'm not at home but I turn the camera to my carport when I'm back from work:`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "800px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/6c25efde0e383845cd524ec97d82aa62/5a190/IP-Symcon-EN_03a.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "56.086956521739125%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAALCAIAAADwazoUAAAACXBIWXMAAAsTAAALEwEAmpwYAAACjElEQVQoz1XO3U9ScQDG8fMfdNFFy5tsWlmNI+eVczRfp0L5xijKpTHdIcC3pLRAYRqgZbleti66aaO1uvTGO2urpsaUANtS0Yms8mVzIAqocDjnd36N1kV97r97HuR5f43nXZ7NpHnmsI5au8ZsPcO9RnNLfbu20t7JOXsanpqPKCl0oNvQceNqq1bNNV2pr7ggO3FclpuD2DiVg5Obr1XZOLXNoO1rVt5trrboVGZtqau7xcJpjBeP1hFnm8oVmjJFDYVW4+caSigy9xidl4OEvvtXAr7Vb3MrAc/ivHfB9yXon1n2zyx5P22uLZraWrEzBeU0jhfkv3gydqe783z+yWKiUK2qulRZhkjwLxFIByl+N5lKQJjmM3uJfa8/0MZxqLxQjhP1jerdeHLa4z1FFo08epxM7vfdsyA8nxaEDABCMpmcnfMFl1cghBvrv2Y8nonxcaPBIJPJcByvra2bmva8//CRIAi73e4L+GtVKkTIZECWCEQQCoW3NzagKEAIv05OTr19bfgTsyxD0/TQfcetHjOOYSRNswR5uaIMEQRRkiCAEgAAShIQsmVodvaz+2UsvsXpb8pQlGWLFQoFwzAYhlE0TaFoY0nR0KAV4Q+T6egan9gGQBIFQRQESZLC895IfGsvFTPq2yqo08oSjCRJhmFYliVIspxRWAf6Bl2DyEEkLKTi6Z0fIn8IJAhEEQBRgnDH8ya6sWjrNemaNO06TSlLUnR2nCLJSqWqv+O6w9KJ8IkIlIB4uAsEXoIwex7CWHTL9+p20DNh7jLoOc6k11E4WiiXY5gcRVG2iG2uIfTaaiQSiUT/txOLba7/DC4thNdW3W633W53ulwj/xp2OZ2O0YcPfgP5Bn1Ik5YvNwAAAABJRU5ErkJggg==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/6c25efde0e383845cd524ec97d82aa62/e4706/IP-Symcon-EN_03a.avif 230w", "/en/static/6c25efde0e383845cd524ec97d82aa62/d1af7/IP-Symcon-EN_03a.avif 460w", "/en/static/6c25efde0e383845cd524ec97d82aa62/c9875/IP-Symcon-EN_03a.avif 800w"],
              "sizes": "(max-width: 800px) 100vw, 800px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/6c25efde0e383845cd524ec97d82aa62/a0b58/IP-Symcon-EN_03a.webp 230w", "/en/static/6c25efde0e383845cd524ec97d82aa62/bc10c/IP-Symcon-EN_03a.webp 460w", "/en/static/6c25efde0e383845cd524ec97d82aa62/d00b9/IP-Symcon-EN_03a.webp 800w"],
              "sizes": "(max-width: 800px) 100vw, 800px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/6c25efde0e383845cd524ec97d82aa62/81c8e/IP-Symcon-EN_03a.png 230w", "/en/static/6c25efde0e383845cd524ec97d82aa62/08a84/IP-Symcon-EN_03a.png 460w", "/en/static/6c25efde0e383845cd524ec97d82aa62/5a190/IP-Symcon-EN_03a.png 800w"],
              "sizes": "(max-width: 800px) 100vw, 800px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/6c25efde0e383845cd524ec97d82aa62/5a190/IP-Symcon-EN_03a.png",
              "alt": "IP Symcon",
              "title": "IP Symcon",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "798px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/4354c7ded2074c6d1d2fcd7a3fd1210c/898f6/IP-Symcon-EN_03b.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "49.130434782608695%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAKCAYAAAC0VX7mAAAACXBIWXMAAAsTAAALEwEAmpwYAAAA50lEQVQoz62SaQqDMBCFPaXrXfQM/lDP6IZa9yW+MkMNxsbSQgdCEsP78l5GwzRNhGGIZVkwDAPOte+7sr4b8zzD9324rgvDsizEccyiuq4xTRODaaaa5gVtP0JXx4VkRgvM8xxd16FpGrRty98e3YC0qG6dUq3rqgKjKOKDLMsYtG0b78Ul8muhgN+Atm1LYFVV7I5cEvQQCSGkeNdc8tFhURRI0xR930sRAWl8BSSHSZLwQVmW3IxxHBXBOZ4OSE0JggCe58FwHEc2hd6P4tJ8xPwmsgI8O6S452h/cUjvcf01fnH4BDWb1I0AyQ/KAAAAAElFTkSuQmCC')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/4354c7ded2074c6d1d2fcd7a3fd1210c/e4706/IP-Symcon-EN_03b.avif 230w", "/en/static/4354c7ded2074c6d1d2fcd7a3fd1210c/d1af7/IP-Symcon-EN_03b.avif 460w", "/en/static/4354c7ded2074c6d1d2fcd7a3fd1210c/7d2e5/IP-Symcon-EN_03b.avif 798w"],
              "sizes": "(max-width: 798px) 100vw, 798px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/4354c7ded2074c6d1d2fcd7a3fd1210c/a0b58/IP-Symcon-EN_03b.webp 230w", "/en/static/4354c7ded2074c6d1d2fcd7a3fd1210c/bc10c/IP-Symcon-EN_03b.webp 460w", "/en/static/4354c7ded2074c6d1d2fcd7a3fd1210c/ce206/IP-Symcon-EN_03b.webp 798w"],
              "sizes": "(max-width: 798px) 100vw, 798px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/4354c7ded2074c6d1d2fcd7a3fd1210c/81c8e/IP-Symcon-EN_03b.png 230w", "/en/static/4354c7ded2074c6d1d2fcd7a3fd1210c/08a84/IP-Symcon-EN_03b.png 460w", "/en/static/4354c7ded2074c6d1d2fcd7a3fd1210c/898f6/IP-Symcon-EN_03b.png 798w"],
              "sizes": "(max-width: 798px) 100vw, 798px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/4354c7ded2074c6d1d2fcd7a3fd1210c/898f6/IP-Symcon-EN_03b.png",
              "alt": "IP Symcon",
              "title": "IP Symcon",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <EuiSpacer mdxType="EuiSpacer" />
    <h3 {...{
      "id": "4-use-different-detection-areas-for-each-position",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#4-use-different-detection-areas-for-each-position",
        "aria-label": "4 use different detection areas for each position permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`4. Use different Detection Areas for each Position`}</h3>
    <p>{`In combination with the scheduled day and night position (see above) you can also activate different motion detection areas - in the example the areas 1 & 2 are activated during the day and the areas 3 & 4 at night:`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "800px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/3388265ef9bd2fb138c259adb722f9e0/5a190/IP-Symcon-EN_04a.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "56.086956521739125%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAALCAIAAADwazoUAAAACXBIWXMAAAsTAAALEwEAmpwYAAACqklEQVQozwGfAmD9AI53RsimI31qU41+dox8cIp5bol6bnxqXGdRP35uZoJvTo5wD0c1LmxdVnJlX1BEPj8yLUAzLUU4MjgrJQB7Y0iBZDBuVEB3X0x9aVh4Xkp1V0J2W0ZxVT6HcmB3YlFoShRILyVXPzVRPzdMOzVKNy5HNSxEMio+KyIA3NPN2M7M29HJ2c/G1c7Kz8rEz8vE1czD1svA4trRamNiMCcoQDYxMicjko2Kcm1sJyUjQDo2amFcUUlFAP7///////////v8/vX3+uzy9vn///b5+ujo6sPGx2lqay0uLjEzNExOT+7w8sXIyycwMoSHiP///3t9fgD29vb4+Pj5+fnw8fHGx8vU1db///7i4ODCxcTCvLxqaWksLS0yMjJLSkrBw8a7vb82NTRiYWGusLBHREQA////////////////6Obm7+vr//39////08LCxqekaGhnLSwsODg4NjY2fn5/bm5sMjAvLi4sMCgnVEA8AMPJz8fT28jQ18jO18vS2c/S1/j6+t/Mzb6clO3w5GZoaSwsLTg5OjY2Njc8QjQ8QjY+RTxCSEtDR2lucACtpa29fYSxp6/G0tjP1t6yucHZ2tvk1tLp7uTx+O9pZ2RANCZHPTEzMzJZXmRTV2JWW2ZIT15dZXLByM8A1Lax1j4uileBX2LK0s7q6+vl1dXW////7Mej7OPSfndsXlhRZl5SPzYzPk48KYIbLGQfbmoSf3886enrAMGsk9txYZNtmF1cxri5wOjn3tXU1/b078ucctfFr3NvaWRjYmZjXjYuLV9vW126UnKsaLi2Vby+eOXk6wBFQ0FLTUtIS0pKTEpHSkVBPkC+vb7t7OvV1NLf3duhoaF7fHuFhoZ3d3ZhXVttaW1tam1oZ2tpaW1pZmgTpGVNW10jyAAAAABJRU5ErkJggg==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/3388265ef9bd2fb138c259adb722f9e0/e4706/IP-Symcon-EN_04a.avif 230w", "/en/static/3388265ef9bd2fb138c259adb722f9e0/d1af7/IP-Symcon-EN_04a.avif 460w", "/en/static/3388265ef9bd2fb138c259adb722f9e0/c9875/IP-Symcon-EN_04a.avif 800w"],
              "sizes": "(max-width: 800px) 100vw, 800px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/3388265ef9bd2fb138c259adb722f9e0/a0b58/IP-Symcon-EN_04a.webp 230w", "/en/static/3388265ef9bd2fb138c259adb722f9e0/bc10c/IP-Symcon-EN_04a.webp 460w", "/en/static/3388265ef9bd2fb138c259adb722f9e0/d00b9/IP-Symcon-EN_04a.webp 800w"],
              "sizes": "(max-width: 800px) 100vw, 800px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/3388265ef9bd2fb138c259adb722f9e0/81c8e/IP-Symcon-EN_04a.png 230w", "/en/static/3388265ef9bd2fb138c259adb722f9e0/08a84/IP-Symcon-EN_04a.png 460w", "/en/static/3388265ef9bd2fb138c259adb722f9e0/5a190/IP-Symcon-EN_04a.png 800w"],
              "sizes": "(max-width: 800px) 100vw, 800px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/3388265ef9bd2fb138c259adb722f9e0/5a190/IP-Symcon-EN_04a.png",
              "alt": "IP Symcon",
              "title": "IP Symcon",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "793px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/58a98e234e8b19a157daffba70a7f8c3/73fd0/IP-Symcon-EN_04b.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "54.78260869565218%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAALCAYAAAB/Ca1DAAAACXBIWXMAAAsTAAALEwEAmpwYAAABCklEQVQoz5WSW46EIBBFe5dqoktQ92Kibs0N+KHtW0dBxZpcJhi6sR9TCQEt6uRAcbMsi6IoIs45LctCehzHcblW3+pf0zTk+z65rks327YpSRKZHMeRGGMSzBiXm2fGafxZDIi+btuWgiAwgWVZ0jAM1Pe9hCO6YaJ70xsQ3folsCgK6rqOtm2TG4XQii+u4C0QUde1NJumifZ9lwWACiEejvrRMI5jmaiq6rTTLb4Bnk1xHIfSNJVFuEPYKUtV8A0wDEPyPO8PqI6MhgCEMc/zJUhZP9/hAxCGSOI9PQfuNcuy843+yxBFsFIDAds8z2ldV8NQ7YGIYYgHjQQagxldRmAGTB3/E/AXWrgYiQwIvRgAAAAASUVORK5CYII=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/58a98e234e8b19a157daffba70a7f8c3/e4706/IP-Symcon-EN_04b.avif 230w", "/en/static/58a98e234e8b19a157daffba70a7f8c3/d1af7/IP-Symcon-EN_04b.avif 460w", "/en/static/58a98e234e8b19a157daffba70a7f8c3/6bf88/IP-Symcon-EN_04b.avif 793w"],
              "sizes": "(max-width: 793px) 100vw, 793px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/58a98e234e8b19a157daffba70a7f8c3/a0b58/IP-Symcon-EN_04b.webp 230w", "/en/static/58a98e234e8b19a157daffba70a7f8c3/bc10c/IP-Symcon-EN_04b.webp 460w", "/en/static/58a98e234e8b19a157daffba70a7f8c3/51ddc/IP-Symcon-EN_04b.webp 793w"],
              "sizes": "(max-width: 793px) 100vw, 793px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/58a98e234e8b19a157daffba70a7f8c3/81c8e/IP-Symcon-EN_04b.png 230w", "/en/static/58a98e234e8b19a157daffba70a7f8c3/08a84/IP-Symcon-EN_04b.png 460w", "/en/static/58a98e234e8b19a157daffba70a7f8c3/73fd0/IP-Symcon-EN_04b.png 793w"],
              "sizes": "(max-width: 793px) 100vw, 793px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/58a98e234e8b19a157daffba70a7f8c3/73fd0/IP-Symcon-EN_04b.png",
              "alt": "IP Symcon",
              "title": "IP Symcon",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <EuiSpacer mdxType="EuiSpacer" />
    <h3 {...{
      "id": "5-use-different-sensitivities-for-day--night",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#5-use-different-sensitivities-for-day--night",
        "aria-label": "5 use different sensitivities for day  night permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`5. Use different Sensitivities for Day & Night`}</h3>
    <p>{`Since there are often different sensitivities needed for the motion detection during day and night conditions, you can also adjust these - in the example to 75% during the day and 25% at night:`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "800px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/9fec29cdade637840b76d773183ac92a/5a190/IP-Symcon-EN_05a.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "56.086956521739125%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAALCAIAAADwazoUAAAACXBIWXMAAAsTAAALEwEAmpwYAAACqklEQVQozwGfAmD9ADG2uZrDWSG64j3G9ELG8T/G8TDC8EDG8S3B8Sy88Gl8aIuFLT1YY2d8gm+CiG1/hnKDiV5yeExiai5JUwBCw+Ngxsc/xvBTzPJMyvJGyPI9xfFGyPEqwfBKx/BdbmtXXTY3TFNJWmBFW2NEX2g+WWI+WWJFX2cxSE8A+fr89/3//P399fz93/L44evu6fP16/f86/f98fj7ZGNkJykuOTk4JCUldIGGyNPWvcbIy9TYwc/WWmVoAPn5+f38+v38/Pn9/ufz9vXz8v///+3z/Mrf9PD6/GZkZC4vLjs8PCgqKpCboP////79/Pf6/uDu/GRwcwD8/Pz9/v79/v32+fzm8fb49vX4+Pe8vL7j7/jv+fxmZGQqKik4NzQhIiCVn6HX1tbY19f////5/f1ibnIAub3AnJ2pp6izqrnB0eTp8Pb34evvt8rX7/r97vj7ZWNjLTE2PkFMLTE8iJihwNHfyNXd5Ors2ubqXGltAMPJy+WRjsZxc6623Nnf+OPs78nR1P3///H5/Pr7+2NiY0pPUlZwY1hqY01USpyloK63uzM7PUFJS0BCQwC6wbjOaFOmN0I3M7J8eLfv7+HLy8z////+/fz7/PtlYmUtNyknlxc2eR+CewK4uX6mpa8mJCI2MzM+PT0ArKqjrZeOrJeXm5u2oqaptLOux8XH////+/v8+/z8ZGNkbm1nl7mToLSbt7WPzs++l5OZMDAvODg4QEBAAN7e38vO0MXJyKysptva2d3c3vPz8/////7+/vz8/GJiYlFQUGJcYpSQlIyMk2loa2FhYTIyMi4uLjk5OQDu7u718/Pr6urc3N3t7e7y8vLt7e3s7Ozs7Ozu7u6goKB9fX2Bg4Gfn56ampmAgH+Dg4OFhYWEhISJiYmNnqcD5EzkhgAAAABJRU5ErkJggg==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/9fec29cdade637840b76d773183ac92a/e4706/IP-Symcon-EN_05a.avif 230w", "/en/static/9fec29cdade637840b76d773183ac92a/d1af7/IP-Symcon-EN_05a.avif 460w", "/en/static/9fec29cdade637840b76d773183ac92a/c9875/IP-Symcon-EN_05a.avif 800w"],
              "sizes": "(max-width: 800px) 100vw, 800px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/9fec29cdade637840b76d773183ac92a/a0b58/IP-Symcon-EN_05a.webp 230w", "/en/static/9fec29cdade637840b76d773183ac92a/bc10c/IP-Symcon-EN_05a.webp 460w", "/en/static/9fec29cdade637840b76d773183ac92a/d00b9/IP-Symcon-EN_05a.webp 800w"],
              "sizes": "(max-width: 800px) 100vw, 800px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/9fec29cdade637840b76d773183ac92a/81c8e/IP-Symcon-EN_05a.png 230w", "/en/static/9fec29cdade637840b76d773183ac92a/08a84/IP-Symcon-EN_05a.png 460w", "/en/static/9fec29cdade637840b76d773183ac92a/5a190/IP-Symcon-EN_05a.png 800w"],
              "sizes": "(max-width: 800px) 100vw, 800px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/9fec29cdade637840b76d773183ac92a/5a190/IP-Symcon-EN_05a.png",
              "alt": "IP Symcon",
              "title": "IP Symcon",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "797px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/76450466740452189ac695ac2955a87e/43fbc/IP-Symcon-EN_05b.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "49.130434782608695%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAKCAYAAAC0VX7mAAAACXBIWXMAAAsTAAALEwEAmpwYAAAA40lEQVQoz5WSDQ5EQAyF95YIF+EKEhwTibWMMeP3bdoNGYPdbJOGybSf1z4Px3EQxzGmaYJSCmas63p4vztTXxiG8H0fD9d1kWUZX7RtC601+r6HUvpTrAe8hDx9xARSzwGYpilfFEUBIQSapuEnhZAK5bO5Vf0VmOc56rrGMAx8Xi6ar0a+BVZVxcq6ruOdUsOyLJzmqD8VJkmyA8dxhB1/AT3P200py5IVkjmkknZJBpnj3QGjKEIQBEcg7Y9gUkoGEZSKbaCZX4Gkbp7n08i2IT8VbqbYRphpK9vqth97A74B523S5+QC+4MAAAAASUVORK5CYII=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/76450466740452189ac695ac2955a87e/e4706/IP-Symcon-EN_05b.avif 230w", "/en/static/76450466740452189ac695ac2955a87e/d1af7/IP-Symcon-EN_05b.avif 460w", "/en/static/76450466740452189ac695ac2955a87e/48116/IP-Symcon-EN_05b.avif 797w"],
              "sizes": "(max-width: 797px) 100vw, 797px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/76450466740452189ac695ac2955a87e/a0b58/IP-Symcon-EN_05b.webp 230w", "/en/static/76450466740452189ac695ac2955a87e/bc10c/IP-Symcon-EN_05b.webp 460w", "/en/static/76450466740452189ac695ac2955a87e/9eee1/IP-Symcon-EN_05b.webp 797w"],
              "sizes": "(max-width: 797px) 100vw, 797px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/76450466740452189ac695ac2955a87e/81c8e/IP-Symcon-EN_05b.png 230w", "/en/static/76450466740452189ac695ac2955a87e/08a84/IP-Symcon-EN_05b.png 460w", "/en/static/76450466740452189ac695ac2955a87e/43fbc/IP-Symcon-EN_05b.png 797w"],
              "sizes": "(max-width: 797px) 100vw, 797px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/76450466740452189ac695ac2955a87e/43fbc/IP-Symcon-EN_05b.png",
              "alt": "IP Symcon",
              "title": "IP Symcon",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <EuiSpacer mdxType="EuiSpacer" />
    <h3 {...{
      "id": "6-turn-off-all-cameras-when-you-arrive-home",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#6-turn-off-all-cameras-when-you-arrive-home",
        "aria-label": "6 turn off all cameras when you arrive home permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`6. Turn off all Cameras when you arrive home`}</h3>
    <p>{`Either schedule or manually disable all alarm detection areas and the PIR sensor, if you do not want to trigger an alarm:`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "800px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/edac5206297fcf2368e6b933e9f71009/5a190/IP-Symcon-EN_06a.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "56.086956521739125%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAALCAIAAADwazoUAAAACXBIWXMAAAsTAAALEwEAmpwYAAACCElEQVQoz12Qu24TQRSGt6DgARAPARLikiAhgURBSYUSOVg0eZsg8RKhokAKBqpUsQ0YOb4sIglJjO/2rrOzu96Z2RnvXA9a2wXwF6c4vz7pfMe59im8U042qslGBT+s4ltHyeOvyYMKvX3U3azW71dG98r4bjnZrOJHX/DNw/jG4fz658g5CJ0PofPsO9lu0mIDFxq42KJbTVpo0UKLbzVGr9puoe1vu2KnRV/Uk5dtVnT5rps+r5Mn3/DTGnEAwABkABxAWPgvRgoaIW80QNMJjpBg6d+tIwGGZPH+oPR2f7952eMA1lqpdRTHo9FwPJmEUTzH+OTsV/24gaK4PxjOEGKZ0ACOAbiY+LvFndd7e1N/NtegpRwMh0EQCCkBQGn9u9P5WCpNPQ8AtNZJkvQHA0KpYwFmXLqNmte/iJVlyoK1hBBrcwdrbSjteE77KI6UNTZvAYAQopTKnaWFuQGfCZzfkgN4CVuA6cJcpqbL7TmznVR7C2uWf8EYr+GYZPUTf8w0VnljjGGMjcfjTq+XasCZZEqnyjANIRc/fp4wxtI0XcPH5+jNO3ehrF592BjOmef5s6urXFIKo7Va+msp+71eJkRKaQ6fDkntLChVuwuhAKxZBiEUBEEURUmSYEIChCaeRwiJ4zgMw9WUUjpcKMoF5VJpvUaNybKMr8MYY/zfrDbGmD8q8EkS4AZurAAAAABJRU5ErkJggg==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/edac5206297fcf2368e6b933e9f71009/e4706/IP-Symcon-EN_06a.avif 230w", "/en/static/edac5206297fcf2368e6b933e9f71009/d1af7/IP-Symcon-EN_06a.avif 460w", "/en/static/edac5206297fcf2368e6b933e9f71009/c9875/IP-Symcon-EN_06a.avif 800w"],
              "sizes": "(max-width: 800px) 100vw, 800px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/edac5206297fcf2368e6b933e9f71009/a0b58/IP-Symcon-EN_06a.webp 230w", "/en/static/edac5206297fcf2368e6b933e9f71009/bc10c/IP-Symcon-EN_06a.webp 460w", "/en/static/edac5206297fcf2368e6b933e9f71009/d00b9/IP-Symcon-EN_06a.webp 800w"],
              "sizes": "(max-width: 800px) 100vw, 800px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/edac5206297fcf2368e6b933e9f71009/81c8e/IP-Symcon-EN_06a.png 230w", "/en/static/edac5206297fcf2368e6b933e9f71009/08a84/IP-Symcon-EN_06a.png 460w", "/en/static/edac5206297fcf2368e6b933e9f71009/5a190/IP-Symcon-EN_06a.png 800w"],
              "sizes": "(max-width: 800px) 100vw, 800px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/edac5206297fcf2368e6b933e9f71009/5a190/IP-Symcon-EN_06a.png",
              "alt": "IP Symcon",
              "title": "IP Symcon",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`Please note for the models `}<strong parentName="p">{`IN-6014 HD`}</strong>{` and `}<strong parentName="p">{`IN-9020 Full HD`}</strong>{`, instead of the PIR sensor - `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`setpirattr&-pir_enable=1`}</code>{` - the alarm input must be switched: `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`cmd=setioattr&-io_enable=1`}</code>{`. The same applies to cameras where an external PIR sensor is attached to the alarm input.`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "797px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/06e0e7a783989d2188d63df0706fcfd8/43fbc/IP-Symcon-EN_06b.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "55.21739130434783%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAALCAYAAAB/Ca1DAAAACXBIWXMAAAsTAAALEwEAmpwYAAABAklEQVQoz42SSY6EMAxF65ogOASwYMFw3ZaYx2Lmt76ldAOpSBXJMiz88hz7ZVkWwjDEOI6YpgnXc57n7dv0X1UVPM+D4zh42baNOI6xriu6rsOyLJjnWTLPsm4Y3pN2yRVY1zV8378D931HnucCbdtWAjjRj2/85JXRWgE1w23bBNg0jdiaik2GGpCGhNFsGAa5QBUdx6EBjYYcShRFUlQUxW0wVyDj65aTJBGjLMvEru97CQX5xjAIAriu+w9kEVeHQSjzJ5Cyfr7hDZimqQyCb/g8NC/L8s/2055qQA6FBdw/9V7KjsNiAfPTTl3CxdZaZos0UaFWhyAOitnU8hX4CxiSFjKa5OHVAAAAAElFTkSuQmCC')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/06e0e7a783989d2188d63df0706fcfd8/e4706/IP-Symcon-EN_06b.avif 230w", "/en/static/06e0e7a783989d2188d63df0706fcfd8/d1af7/IP-Symcon-EN_06b.avif 460w", "/en/static/06e0e7a783989d2188d63df0706fcfd8/48116/IP-Symcon-EN_06b.avif 797w"],
              "sizes": "(max-width: 797px) 100vw, 797px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/06e0e7a783989d2188d63df0706fcfd8/a0b58/IP-Symcon-EN_06b.webp 230w", "/en/static/06e0e7a783989d2188d63df0706fcfd8/bc10c/IP-Symcon-EN_06b.webp 460w", "/en/static/06e0e7a783989d2188d63df0706fcfd8/9eee1/IP-Symcon-EN_06b.webp 797w"],
              "sizes": "(max-width: 797px) 100vw, 797px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/06e0e7a783989d2188d63df0706fcfd8/81c8e/IP-Symcon-EN_06b.png 230w", "/en/static/06e0e7a783989d2188d63df0706fcfd8/08a84/IP-Symcon-EN_06b.png 460w", "/en/static/06e0e7a783989d2188d63df0706fcfd8/43fbc/IP-Symcon-EN_06b.png 797w"],
              "sizes": "(max-width: 797px) 100vw, 797px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/06e0e7a783989d2188d63df0706fcfd8/43fbc/IP-Symcon-EN_06b.png",
              "alt": "IP Symcon",
              "title": "IP Symcon",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <EuiSpacer mdxType="EuiSpacer" />
    <h3 {...{
      "id": "7-use-external-sensors-to-turn-your-camera-when-motion-is-detected",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#7-use-external-sensors-to-turn-your-camera-when-motion-is-detected",
        "aria-label": "7 use external sensors to turn your camera when motion is detected permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`7. Use external Sensors to turn your Camera when Motion is detected`}</h3>
    <p>{`Let your camera move between positions depending on system variables in home automation system. For example - when the door sensor is tripped, look to the door, when the window sensor is triggered turn towards the window and when the external motion sensor detects a person, turn to the kitchen door:`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "800px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/87ccf420286affd48f9e56dfe57b6601/5a190/IP-Symcon-EN_07a.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "56.086956521739125%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAALCAIAAADwazoUAAAACXBIWXMAAAsTAAALEwEAmpwYAAACUElEQVQozz3Ju27TUADGcc88AhNibJCKkBhgAKk3BAwMLCyIF2Doi/AGwMICLAgQpaItSUqaa9OExLk6zT2OY/tcbJ9j+5xj+6B0QPqW7/9Trh+j21mcSqO7p3gzi1MZtJHB93LOjWN08ze+f+ZspPFGBt85xbcyeDOLUhmUSqNrB0D5aitbBWevRHYrdK9MtvP4adXfLnk7Rfdx2XtVIy9r5GHB3S66O0V3K+88qtDdCn1WpU/K3oO8o0gZEwdNeh0e0CT0W/UqIZ6UUiaRjIWMuJTJ+sWxoKRxXh73O76DpIxlJJS5bs+BewncPgq0y+GvzGnfYW4kTRsS3zdMuLCwK+U4SFr9QTaXbxhoiqmNHYgdhWhd3lGlZDIOeUhY6MuIJcLngcdDShwUeijhNBFBHIURZ1IKmfCIURZ4CmvmkuPvYtjgVhsuVX/VZGaTWQ1h1kO7F8IJByMGRsLuuKuWY6jMbIorDcyWwrW6W8qhZlXC3mKpusZfAXpc+xTl90O9yFxLeEBQFKG+uWwAQ02ACkbF4OJNOD1RgmHZrB2uWieR2RhoF2BWE3jI22/jo+ehXiRG1x/lyaQsjPpiXJ9q59LTsgcftI8vYj2t0PZPVv8iuj+sYS5fOVc7TX9c4Oo7nnvt60V/8Cesfg7Vb+6soHbbZ5UymxWqh+/nh/t8eqQEYMDgkMOBT2YLCC0Hcm/C6YwFi4AuAzReKxoE3kSH1srBnMxc+5L505DOFROsLGiuBwzLWti2bsLVOgLDhqaN7P9q2/par7oFjJW1/AdWOzM+An/cxwAAAABJRU5ErkJggg==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/87ccf420286affd48f9e56dfe57b6601/e4706/IP-Symcon-EN_07a.avif 230w", "/en/static/87ccf420286affd48f9e56dfe57b6601/d1af7/IP-Symcon-EN_07a.avif 460w", "/en/static/87ccf420286affd48f9e56dfe57b6601/c9875/IP-Symcon-EN_07a.avif 800w"],
              "sizes": "(max-width: 800px) 100vw, 800px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/87ccf420286affd48f9e56dfe57b6601/a0b58/IP-Symcon-EN_07a.webp 230w", "/en/static/87ccf420286affd48f9e56dfe57b6601/bc10c/IP-Symcon-EN_07a.webp 460w", "/en/static/87ccf420286affd48f9e56dfe57b6601/d00b9/IP-Symcon-EN_07a.webp 800w"],
              "sizes": "(max-width: 800px) 100vw, 800px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/87ccf420286affd48f9e56dfe57b6601/81c8e/IP-Symcon-EN_07a.png 230w", "/en/static/87ccf420286affd48f9e56dfe57b6601/08a84/IP-Symcon-EN_07a.png 460w", "/en/static/87ccf420286affd48f9e56dfe57b6601/5a190/IP-Symcon-EN_07a.png 800w"],
              "sizes": "(max-width: 800px) 100vw, 800px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/87ccf420286affd48f9e56dfe57b6601/5a190/IP-Symcon-EN_07a.png",
              "alt": "IP Symcon",
              "title": "IP Symcon",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`This can be combined well with the parking position in the camera - the camera then automatically returns to the standard position after x minutes.`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "796px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/9d07377105e737686f6ca84e832cf841/d48f1/IP-Symcon-EN_07b.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "49.130434782608695%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAKCAYAAAC0VX7mAAAACXBIWXMAAAsTAAALEwEAmpwYAAAA40lEQVQoz42SaQqEMAyFPaXrXfQM/lCPKFUG961a35BAxenojIXQJeRL8lLLtm3EcQwpJcZxxHnt+/5xvrNlWRCGIXzfh+U4DtI05aCmaTDPM4Zh4J3WvEi0/WciM+G6rtfAsizR9z2Du67jN4KJV31b6SUwSRJ2CCHQti22beO7Mlo290ug67oHsKoqBlKVBNVBSqkv4M+WNTDPc0zTdADOQLJHQKowyzJ2FEXBkyb99FBMva6A9EOiKEIQBLA8zzuGUtc1t0ttm0AT9gh49w9Jz3OCv0CtIWlxHoI2ApLvCfANmWnU0xjYtE4AAAAASUVORK5CYII=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/9d07377105e737686f6ca84e832cf841/e4706/IP-Symcon-EN_07b.avif 230w", "/en/static/9d07377105e737686f6ca84e832cf841/d1af7/IP-Symcon-EN_07b.avif 460w", "/en/static/9d07377105e737686f6ca84e832cf841/9b205/IP-Symcon-EN_07b.avif 796w"],
              "sizes": "(max-width: 796px) 100vw, 796px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/9d07377105e737686f6ca84e832cf841/a0b58/IP-Symcon-EN_07b.webp 230w", "/en/static/9d07377105e737686f6ca84e832cf841/bc10c/IP-Symcon-EN_07b.webp 460w", "/en/static/9d07377105e737686f6ca84e832cf841/7fcbc/IP-Symcon-EN_07b.webp 796w"],
              "sizes": "(max-width: 796px) 100vw, 796px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/9d07377105e737686f6ca84e832cf841/81c8e/IP-Symcon-EN_07b.png 230w", "/en/static/9d07377105e737686f6ca84e832cf841/08a84/IP-Symcon-EN_07b.png 460w", "/en/static/9d07377105e737686f6ca84e832cf841/d48f1/IP-Symcon-EN_07b.png 796w"],
              "sizes": "(max-width: 796px) 100vw, 796px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/9d07377105e737686f6ca84e832cf841/d48f1/IP-Symcon-EN_07b.png",
              "alt": "IP Symcon",
              "title": "IP Symcon",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <EuiSpacer mdxType="EuiSpacer" />
    <h3 {...{
      "id": "8-use-external-sensors-to-trigger-alarm-recordings",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#8-use-external-sensors-to-trigger-alarm-recordings",
        "aria-label": "8 use external sensors to trigger alarm recordings permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`8. Use external Sensors to trigger Alarm Recordings`}</h3>
    <p>{`When you set up your camera to turn to a position when triggered by an external sensor, you probably want to start an alarm recording on the internal SD card to catch the intruder. This can be done by starting the manual recording through your home automation system and after a delay following up the stop command. Due to adjustable delay, the length of the alarm video is freely selectable (max. 15min):`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "800px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/4506e9254e37c10afe3c4345eea55b54/5a190/IP-Symcon-EN_08a.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "56.086956521739125%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAALCAIAAADwazoUAAAACXBIWXMAAAsTAAALEwEAmpwYAAAB7klEQVQozz2O227aQBCG/Xp9lb5Iq15VVR8hV7lIm6iKWrWKohKSAilgcxAG2wSwvT5jvOtdz+7irWxoRt/Fp9H/a0ZTqm5RggtKmTpPnRdFnMRpmqAA5UVx2lJKwzCM44gQrFSt/U8rUjLXj0ulMqGKo3K5Sg9kaZpfb797Url7vE4OGATBBBNa1809TeQvJ2C/LsMlJBYkdhWvxN5ZL55/3F470y7PnWw3SbdTmtostSBzRL4W+YsG4fhMMBS5w/NA4pjvXYgMFuqJOxLJBCJdxrpAT+B1IdJfKxpEk5ZpFYxl4UpaHCsiy4wncxIYIZrxJq1X6UpOP/PhO0hNiIxTS2vtDN31sfVAnCdsdXgwOgTGajeTsaEO5rBzs7h6q6YfqmTRHG/zGoT6mciA3RPYd2DdNRKON+bfx27HXz6qRL//dvHz4xs1e8+ieftzU9EY6jHUo94fGj5T46rsX5b9S2Z8ybzB1F7+nliT+UBtH0a/Lsz7T/X2uvQH1O81oJ5WScIkETVuUKVQrKUUR5zu4zQ/UHZQigbRlgGRqhJHfA4fseYx6hDmo5UfOK63RIGNAnu7M31ke8j2fHuzM11vFScbhGzXX3m+5Qf2ZrvIck9jEkoJwEvgpOIERCtAKsCNi7JxTuCV05ITLuk/EZJX6fHldqQAAAAASUVORK5CYII=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/4506e9254e37c10afe3c4345eea55b54/e4706/IP-Symcon-EN_08a.avif 230w", "/en/static/4506e9254e37c10afe3c4345eea55b54/d1af7/IP-Symcon-EN_08a.avif 460w", "/en/static/4506e9254e37c10afe3c4345eea55b54/c9875/IP-Symcon-EN_08a.avif 800w"],
              "sizes": "(max-width: 800px) 100vw, 800px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/4506e9254e37c10afe3c4345eea55b54/a0b58/IP-Symcon-EN_08a.webp 230w", "/en/static/4506e9254e37c10afe3c4345eea55b54/bc10c/IP-Symcon-EN_08a.webp 460w", "/en/static/4506e9254e37c10afe3c4345eea55b54/d00b9/IP-Symcon-EN_08a.webp 800w"],
              "sizes": "(max-width: 800px) 100vw, 800px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/4506e9254e37c10afe3c4345eea55b54/81c8e/IP-Symcon-EN_08a.png 230w", "/en/static/4506e9254e37c10afe3c4345eea55b54/08a84/IP-Symcon-EN_08a.png 460w", "/en/static/4506e9254e37c10afe3c4345eea55b54/5a190/IP-Symcon-EN_08a.png 800w"],
              "sizes": "(max-width: 800px) 100vw, 800px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/4506e9254e37c10afe3c4345eea55b54/5a190/IP-Symcon-EN_08a.png",
              "alt": "IP Symcon",
              "title": "IP Symcon",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "bash"
    }}><pre parentName="div" {...{
        "className": "language-bash"
      }}><code parentName="pre" {...{
          "className": "language-bash"
        }}>{`/param.cgi?cmd`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{`manualrec`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`&`}</span>{`-act`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{`on`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`&`}</span>{`-time`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span><span parentName="code" {...{
            "className": "token number"
          }}>{`60`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`&`}</span>{`-usr`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`USERNAME`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`&`}</span>{`-pwd`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`PASSWORD`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span>{`

/param.cgi?/cmd`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{`manualrec`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`&`}</span>{`-act`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{`off`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`&`}</span>{`-usr`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`USERNAME`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`&`}</span>{`-pwd`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`PASSWORD`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span></code></pre></div>
    <EuiSpacer mdxType="EuiSpacer" />
    <h3 {...{
      "id": "9-nach-geräuschquellen-suchen",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#9-nach-ger%C3%A4uschquellen-suchen",
        "aria-label": "9 nach geräuschquellen suchen permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`9. Nach Geräuschquellen suchen`}</h3>
    <p>{`Wenn die Audioerkennung Ihrer Kamera auslöst, kennen Sie wahrscheinlich nicht den Ort der Geräuschquelle. Sie können in einem solchen Fall einen horizontalen (`}<code parentName="p" {...{
        "className": "language-text"
      }}>{`hscan`}</code>{`) oder vertikalen (`}<code parentName="p" {...{
        "className": "language-text"
      }}>{`vscan`}</code>{`) Suchlauf auslösen. Dies muss dann manuell mit der gewünschten Verzögerung wieder gestoppt werden:`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "800px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/041d456258b0609cfe1afb30e5efd849/5a190/IP-Symcon-EN_09a.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "56.086956521739125%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAALCAIAAADwazoUAAAACXBIWXMAAAsTAAALEwEAmpwYAAACDElEQVQoz3WLz27TQBjE/WzceAR4A+5ckJC4wQHOrQCJWw+VEFQIEBTRlqZ/Utw4qe20addu3Nhrx5vGieNde7/dtRclPSHE6KcZaTRjaN38zVJSiizLCEmLosimU8bosm3qFc2S1djQ/5FSirLytGM5jrs8Ns2/G0POgztEds1vEUx9PkFyPmQTNHC7nz5sxsjUNBQlloGtLkx548j4XGZDOQ8MSHtLxpaYIjFPZDERM8wn/UVwdN4/OLuydOZA3IHLFjjf4dua+PaW93fuXgYQB4gLaU/lI1XmNaeKzcQMhfYPyzkcIPPy5PMi6cqdj/LoTbP1oD58XlMPiA3EMVZhw8Qpw5PCa9Hr48I7qGKrwp2Oc9wf/J4FbYo70txme2vD9fvu5qPe0Vc1dYHYBqRnK2xILPB3wd/lw18i7VHcvfEHrf39sW+q8SngHhy+7q/fe//q4dMnj/ORKYltVMlJlbTLsVle77D2BrO2WHuDh60oPOsE0V7fd5Ej0M8Kt6vBF33xrru99vLFs1tvF4hpcEVLWciGKs2ELqWupC6VplwsCEnzPC/5Qmkq60LUrNZ1FA+R75JpVOvSSHkVV5CM/ZvRgEwCjFGIr6IYhRiR21GEvQgjnHg48ePEixMUJx6HmWoWQs2NSgFTAgRlVS5UCUA5UC6WLiQDyUAwuGsEBcE4FEKVqqlUw/8AMghSN3pawy8AAAAASUVORK5CYII=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/041d456258b0609cfe1afb30e5efd849/e4706/IP-Symcon-EN_09a.avif 230w", "/en/static/041d456258b0609cfe1afb30e5efd849/d1af7/IP-Symcon-EN_09a.avif 460w", "/en/static/041d456258b0609cfe1afb30e5efd849/c9875/IP-Symcon-EN_09a.avif 800w"],
              "sizes": "(max-width: 800px) 100vw, 800px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/041d456258b0609cfe1afb30e5efd849/a0b58/IP-Symcon-EN_09a.webp 230w", "/en/static/041d456258b0609cfe1afb30e5efd849/bc10c/IP-Symcon-EN_09a.webp 460w", "/en/static/041d456258b0609cfe1afb30e5efd849/d00b9/IP-Symcon-EN_09a.webp 800w"],
              "sizes": "(max-width: 800px) 100vw, 800px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/041d456258b0609cfe1afb30e5efd849/81c8e/IP-Symcon-EN_09a.png 230w", "/en/static/041d456258b0609cfe1afb30e5efd849/08a84/IP-Symcon-EN_09a.png 460w", "/en/static/041d456258b0609cfe1afb30e5efd849/5a190/IP-Symcon-EN_09a.png 800w"],
              "sizes": "(max-width: 800px) 100vw, 800px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/041d456258b0609cfe1afb30e5efd849/5a190/IP-Symcon-EN_09a.png",
              "alt": "IP Symcon",
              "title": "IP Symcon",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`Alternativ können Sie die Tourfunktion verwenden, um zwischen vordefinierten Positionen zu schwenken, `}<a parentName="p" {...{
        "href": "/en/Web_User_Interface/1080p_Series/Features/PTZ_Tour/"
      }}>{`die in der WebUI festgelegt wurde`}</a>{`. Eine solche Tour kann, je nach Einstellung, max. 50 mal durchfahren werden und die Kamera stoppt danach wieder automatisch (mit Ausnahme des Modelle `}<strong parentName="p">{`IN-9010 Full HD`}</strong>{` und `}<strong parentName="p">{`IN-9020 Full HD`}</strong>{`, bei der die programmierte Tour so lange ausgeführt wird, bis Sie sie manuell stoppen).`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "799px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/0e9f7b24798baaedb572715d16c90a8f/76cea/IP-Symcon-EN_09b.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "41.30434782608695%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAICAYAAAD5nd/tAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAy0lEQVQoz42R0QqEIBBF9yuL+oceqk+ovnUfhEpFc7W6y7hbaEUkDCro4cydV5IkKIoC4zhimiaEa11xuK/ROaymaZBlGV5pmqIsSyilIKWEMQZa6z98hXUOXKodFu5b0WrbNgYSpO97cM4hhAAXwj/W5oM360/AEHoJJCMCDsPgLa8+hzkcDU8tE5DMKEtq3Vq7f1iW5bkhDSU0pCyPgyAg1R0wMqzr2mfIGPOWZEhZOuci6B2w6zrkef4DVlXlcwsnTDXP8+OWN+AX7cw/0fPuNXkAAAAASUVORK5CYII=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/0e9f7b24798baaedb572715d16c90a8f/e4706/IP-Symcon-EN_09b.avif 230w", "/en/static/0e9f7b24798baaedb572715d16c90a8f/d1af7/IP-Symcon-EN_09b.avif 460w", "/en/static/0e9f7b24798baaedb572715d16c90a8f/eb911/IP-Symcon-EN_09b.avif 799w"],
              "sizes": "(max-width: 799px) 100vw, 799px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/0e9f7b24798baaedb572715d16c90a8f/a0b58/IP-Symcon-EN_09b.webp 230w", "/en/static/0e9f7b24798baaedb572715d16c90a8f/bc10c/IP-Symcon-EN_09b.webp 460w", "/en/static/0e9f7b24798baaedb572715d16c90a8f/a2266/IP-Symcon-EN_09b.webp 799w"],
              "sizes": "(max-width: 799px) 100vw, 799px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/0e9f7b24798baaedb572715d16c90a8f/81c8e/IP-Symcon-EN_09b.png 230w", "/en/static/0e9f7b24798baaedb572715d16c90a8f/08a84/IP-Symcon-EN_09b.png 460w", "/en/static/0e9f7b24798baaedb572715d16c90a8f/76cea/IP-Symcon-EN_09b.png 799w"],
              "sizes": "(max-width: 799px) 100vw, 799px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/0e9f7b24798baaedb572715d16c90a8f/76cea/IP-Symcon-EN_09b.png",
              "alt": "IP Symcon",
              "title": "IP Symcon",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <EuiSpacer mdxType="EuiSpacer" />
    <h3 {...{
      "id": "10-manually-disable-all-alarm-functions-for-all-your-cameras",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#10-manually-disable-all-alarm-functions-for-all-your-cameras",
        "aria-label": "10 manually disable all alarm functions for all your cameras permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`10. Manually disable all alarm functions for all your cameras`}</h3>
    <p>{`If you have more than one alarm trigger activated in your camera - PIR sensor, alarm zones, audio detection, alarm input - you can activate and deactivate them simultaneously via the alarm schedule without having to address each trigger individually:`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "800px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/c6fa53957e5ab3c85869c2fb05b663c5/5a190/IP-Symcon-EN_10a.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "56.086956521739125%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAALCAIAAADwazoUAAAACXBIWXMAAAsTAAALEwEAmpwYAAACIUlEQVQoz03QTU8TURQG4Fm7McaFLkzcawxo2ICJP8AFQVj4C0xcGBNXxh9FYuICAxHaUkBbixXQtlKm2M5M587MnXvunXvn836YihjenJzFeRYnea27W2Rhl96vwXwd5htwazO+8TG+uRlf38B3ttiT/d8P6917NbTQYI8aMFeHBzUyV4fbm/G1DWw9/ULX2nStzZ61k+UWW9pnjw/40h5baMLzjnjxfbrc6q22wtU2X2knK1/56qFY6/DFPbrYpJbRylyNrEzK/x2VnM1VLMuCJ1VZXKjlsGyndbh31EuUKcty4k0Hw+E0CAupKimRKHa/HdU6XVworfXYcQanw7HjFJUspLK69vjtq5eftrdxZdIk8REKwzCO41QIqfUogndvXr9fX8el4UIEQXihnHMppeWxtG/biGcuhoTSPM8mk0m/1+NCUEJwXvXt0ZQmDmEUIM9zx3FOTo7TNMUYW0rJVJtMGwpUKYVZslNvnI0nxhiMsVIq1SbXBgC0UiDS5sHnn6fDC7XirBRFZYxJGEOMT7NZSU5WMZEBIVrrspS5MpQx4OJcVMYoN5MgUgrEcjBfb9iVMhTgR68vsswY4wehPToHAGP0h+Zo4AvGk8HgF7Bk9hPg9OwMCLHyvNzuODbKCAHP8yilADSKIt/3GaPnSNS7HuGF4MmlAsZ46vuEEGtWXxgFIUYIua7rep57GYyjMMJRNNtBEPxX568ihP4ApVI/8CO7HmwAAAAASUVORK5CYII=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/c6fa53957e5ab3c85869c2fb05b663c5/e4706/IP-Symcon-EN_10a.avif 230w", "/en/static/c6fa53957e5ab3c85869c2fb05b663c5/d1af7/IP-Symcon-EN_10a.avif 460w", "/en/static/c6fa53957e5ab3c85869c2fb05b663c5/c9875/IP-Symcon-EN_10a.avif 800w"],
              "sizes": "(max-width: 800px) 100vw, 800px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/c6fa53957e5ab3c85869c2fb05b663c5/a0b58/IP-Symcon-EN_10a.webp 230w", "/en/static/c6fa53957e5ab3c85869c2fb05b663c5/bc10c/IP-Symcon-EN_10a.webp 460w", "/en/static/c6fa53957e5ab3c85869c2fb05b663c5/d00b9/IP-Symcon-EN_10a.webp 800w"],
              "sizes": "(max-width: 800px) 100vw, 800px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/c6fa53957e5ab3c85869c2fb05b663c5/81c8e/IP-Symcon-EN_10a.png 230w", "/en/static/c6fa53957e5ab3c85869c2fb05b663c5/08a84/IP-Symcon-EN_10a.png 460w", "/en/static/c6fa53957e5ab3c85869c2fb05b663c5/5a190/IP-Symcon-EN_10a.png 800w"],
              "sizes": "(max-width: 800px) 100vw, 800px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/c6fa53957e5ab3c85869c2fb05b663c5/5a190/IP-Symcon-EN_10a.png",
              "alt": "IP Symcon",
              "title": "IP Symcon",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "bash"
    }}><pre parentName="div" {...{
        "className": "language-bash"
      }}><code parentName="pre" {...{
          "className": "language-bash"
        }}>{`/param.cgi?cmd`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{`setscheduleex`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`&`}</span>{`-ename`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{`md`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`&`}</span>{`-week0`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{`NNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNN`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`&`}</span>{`-week1`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{`NNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNN`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`&`}</span>{`-week2`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{`NNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNN`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`&`}</span>{`-week3`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{`NNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNN`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`&`}</span>{`-week4`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{`NNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNN`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`&`}</span>{`-week5`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{`NNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNN`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`&`}</span>{`-week6`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{`NNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNN`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`&`}</span>{`-usr`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`USERNAME`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`&`}</span>{`-pwd`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`PASSWORD`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span>{`

/param.cgi?cmd`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{`setscheduleex`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`&`}</span>{`-ename`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{`md`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`&`}</span>{`-week0`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{`PPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPP`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`&`}</span>{`-week1`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{`PPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPP`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`&`}</span>{`-week2`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{`PPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPP`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`&`}</span>{`-week3`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{`PPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPP`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`&`}</span>{`-week4`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{`PPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPP`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`&`}</span>{`-week5`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{`PPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPP`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`&`}</span>{`-week6`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{`PPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPP`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`&`}</span>{`-usr`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`USERNAME`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`&`}</span>{`-pwd`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`PASSWORD`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span></code></pre></div>
    <p>{`In this command, `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`week0 - week6`}</code>{` stands for Monday through Sunday and each `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`P`}</code>{` is half an hour of the day the alarm schedule is active (`}<em parentName="p">{`48 x 30min = 24h`}</em>{`). An `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`N`}</code>{` equals a 30-minute period in which it is disabled. The schedule activates / deactivates all alarm actions.`}</p>
    <p><strong parentName="p">{`Update November 2019`}</strong>{` `}<em parentName="p">{`(only for Full HD Camera models)`}</em></p>
    <p>{`Due to a change in the web user interface the alarm schedule is now stored in a new variable called `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`setbackupschedule`}</code>{`. If you use the command above, using just `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`setscheduleex`}</code>{`, the active alarm schedule will be changed but this change will not be visible inside the webUI. It is recommended to always switch both variables to prevent a de-sync:`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "bash"
    }}><pre parentName="div" {...{
        "className": "language-bash"
      }}><code parentName="pre" {...{
          "className": "language-bash"
        }}>{`/param.cgi?cmd`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{`setscheduleex`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`&`}</span>{`-ename`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{`md`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`&`}</span>{`-week0`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{`NNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNN`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`&`}</span>{`-week1`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{`NNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNN`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`&`}</span>{`-week2`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{`NNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNN`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`&`}</span>{`-week3`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{`NNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNN`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`&`}</span>{`-week4`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{`NNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNN`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`&`}</span>{`-week5`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{`NNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNN`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`&`}</span>{`-week6`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{`NNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNN`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`&`}</span><span parentName="code" {...{
            "className": "token assign-left variable"
          }}>{`cmd`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{`setbackupschedule`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`&`}</span>{`-ename`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{`md`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`&`}</span>{`-week0`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{`NNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNN`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`&`}</span>{`-week1`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{`NNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNN`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`&`}</span>{`-week2`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{`NNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNN`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`&`}</span>{`-week3`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{`NNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNN`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`&`}</span>{`-week4`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{`NNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNN`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`&`}</span>{`-week5`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{`NNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNN`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`&`}</span>{`-week6`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{`NNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNN`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`&`}</span>{`-usr`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`USERNAME`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`&`}</span>{`-pwd`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`PASSWORD`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span></code></pre></div>
    <EuiSpacer mdxType="EuiSpacer" />
    <h3 {...{
      "id": "12-switch-between-pir-and-software-motion-detection",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#12-switch-between-pir-and-software-motion-detection",
        "aria-label": "12 switch between pir and software motion detection permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`12. Switch between PIR and Software Motion Detection`}</h3>
    <p>{`Most of our new cameras have both an internal, software-based motion detection and a hardware PIR (passive infrared) heat sensor to detect motion of objects that have a different surface temperature as the ambient. In most cases we recommend that you `}<a parentName="p" {...{
        "href": "/en/Web_User_Interface/1080p_Series/Alarm/Actions/"
      }}>{`link both`}</a>{` as they have different strengths and weaknesses and the coupling can filter many sources of false alerts.`}</p>
    <p>{`But there are situation where one of those two ways to detect motion simply fails and does not provide any filtering effect anymore. Examples are:`}</p>
    <ul>
      <li parentName="ul">{`The PIR is exposed to direct sunlight through most of the day. Shadows and temperature changes trigger regular false alarms.`}</li>
      <li parentName="ul">{`The Motion Detection Areas are constantly triggered at night due to the light from passing cars.`}</li>
      <li parentName="ul">{`etc.`}</li>
    </ul>
    <p>{`We can use our smarthome to automatically deactivate the problematic sensor during times where we know that their accuracy is affected. Since we probably want to use both sensors during times of the day when both work perfectly, we also have to switch the coupling on and off, accordingly.`}</p>
    <p><strong parentName="p">{`Link between PIR and Alarm Areas`}</strong></p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "bash"
    }}><pre parentName="div" {...{
        "className": "language-bash"
      }}><code parentName="pre" {...{
          "className": "language-bash"
        }}>{`/param.cgi?cmd`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{`setmdalarm`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`&`}</span>{`-aname`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{`type`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`&`}</span>{`-switch`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{`on

/param.cgi?cmd`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{`setmdalarm`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`&`}</span>{`-aname`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{`type`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`&`}</span>{`-switch`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{`off`}</code></pre></div>
    <p><strong parentName="p">{`Alarm Areas Activation / Deactivation`}</strong></p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "bash"
    }}><pre parentName="div" {...{
        "className": "language-bash"
      }}><code parentName="pre" {...{
          "className": "language-bash"
        }}>{`/param.cgi?cmd`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{`setmdattr`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`&`}</span>{`-name`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span><span parentName="code" {...{
            "className": "token number"
          }}>{`1`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`&`}</span>{`-enable`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span><span parentName="code" {...{
            "className": "token number"
          }}>{`1`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`&`}</span><span parentName="code" {...{
            "className": "token assign-left variable"
          }}>{`cmd`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{`setmdattr`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`&`}</span>{`-name`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span><span parentName="code" {...{
            "className": "token number"
          }}>{`2`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`&`}</span>{`-enable`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span><span parentName="code" {...{
            "className": "token number"
          }}>{`1`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`&`}</span><span parentName="code" {...{
            "className": "token assign-left variable"
          }}>{`cmd`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{`setmdattr`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`&`}</span>{`-name`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span><span parentName="code" {...{
            "className": "token number"
          }}>{`3`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`&`}</span>{`-enable`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span><span parentName="code" {...{
            "className": "token number"
          }}>{`1`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`&`}</span><span parentName="code" {...{
            "className": "token assign-left variable"
          }}>{`cmd`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{`setmdattr`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`&`}</span>{`-name`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span><span parentName="code" {...{
            "className": "token number"
          }}>{`4`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`&`}</span>{`-enable`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span><span parentName="code" {...{
            "className": "token number"
          }}>{`1`}</span>{`

/param.cgi?cmd`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{`setmdattr`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`&`}</span>{`-name`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span><span parentName="code" {...{
            "className": "token number"
          }}>{`1`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`&`}</span>{`-enable`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span><span parentName="code" {...{
            "className": "token number"
          }}>{`0`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`&`}</span><span parentName="code" {...{
            "className": "token assign-left variable"
          }}>{`cmd`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{`setmdattr`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`&`}</span>{`-name`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span><span parentName="code" {...{
            "className": "token number"
          }}>{`2`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`&`}</span>{`-enable`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span><span parentName="code" {...{
            "className": "token number"
          }}>{`0`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`&`}</span><span parentName="code" {...{
            "className": "token assign-left variable"
          }}>{`cmd`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{`setmdattr`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`&`}</span>{`-name`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span><span parentName="code" {...{
            "className": "token number"
          }}>{`3`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`&`}</span>{`-enable`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span><span parentName="code" {...{
            "className": "token number"
          }}>{`0`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`&`}</span><span parentName="code" {...{
            "className": "token assign-left variable"
          }}>{`cmd`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{`setmdattr`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`&`}</span>{`-name`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span><span parentName="code" {...{
            "className": "token number"
          }}>{`4`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`&`}</span>{`-enable`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span><span parentName="code" {...{
            "className": "token number"
          }}>{`0`}</span></code></pre></div>
    <p><strong parentName="p">{`PIR Activation / Deactivation`}</strong></p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "bash"
    }}><pre parentName="div" {...{
        "className": "language-bash"
      }}><code parentName="pre" {...{
          "className": "language-bash"
        }}>{`/param.cgi?cmd`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{`setpirattr`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`&`}</span>{`-pir_enable`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span><span parentName="code" {...{
            "className": "token number"
          }}>{`1`}</span>{`

/param.cgi?cmd`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{`setpirattr`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`&`}</span>{`-pir_enable`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span><span parentName="code" {...{
            "className": "token number"
          }}>{`0`}</span></code></pre></div>
    <p>{`In case of the IN-6014 HD or IN-9020/10 Full HD (also camera models with attached external PIR sensors) you have to switch the alarm input instead:`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "bash"
    }}><pre parentName="div" {...{
        "className": "language-bash"
      }}><code parentName="pre" {...{
          "className": "language-bash"
        }}>{`/param.cgi?cmd`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{`setioattr`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`&`}</span>{`-io_enable`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span><span parentName="code" {...{
            "className": "token number"
          }}>{`1`}</span>{`

/param.cgi?cmd`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{`setioattr`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`&`}</span>{`-io_enable`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span><span parentName="code" {...{
            "className": "token number"
          }}>{`0`}</span></code></pre></div>
    <EuiSpacer mdxType="EuiSpacer" />
    <ForumBox mdxType="ForumBox" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      